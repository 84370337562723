<template>
    <div class="composition-show">
        <div class="mentality-top2">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="titlebox">{{categoryTitle}}</div>
        </div>
        <div class="contentbox">
            <div class="titlebox">
                <div class="atitle">{{title}}</div>
                <div class="btitle" v-if="writer || time || num"><span v-if="writer">编辑：{{writer}}</span><span v-if="time">时间：{{time}}</span><span v-if="num">浏览量：{{num}}</span></div>
            </div>
            <div class="conbox" v-html="string" style="white-space:pre-wrap;"></div>
            <div class="qrcode">
                <!-- <div class="picbox">
                    <div class="pic" style="background-image: url(/img/right_qrcode.png)"></div>
                </div>
                <img :src="baseUrl + base.wxLogo" alt="扫一扫"> -->
                <div style=" font-weight:bold; text-align:center; font-size: 20px;color: #ffffff">
                    扫码继续
                </div>
                <div class="qr-c">
                    <div ref="qrCode"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getBase,getInfo } from "@/js/request";
import QRCode from 'qrcode';
import { isPhone } from "../utils/tool"

export default {
    name: 'CompositionShow',
    components: {

    },
    data() {
        return {
            base: {},
            baseUrl: '',


            categoryTitle: '',
            title: '',
            writer: '',
            time: '',
            num: '',
            string: ''
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({ path: adress, query: cate });
        }
    },
    mounted() {
        this.$refs.qrCode.innerHTML = '';
        QRCode.toCanvas(window.location.href, { errorCorrectionLevel: 'H', width: 100 }, (err, canvas) => {
            if (err) throw error
            this.$refs.qrCode.appendChild(canvas)
        });
        if(isPhone()){
            this.$root.hideRight();
        }else{
            this.$root.showRight();
        }
        getInfo(this.$route.query.id).then(res => {
            this.title = res.data.title;
            this.categoryTitle = res.data.categoryTitle;
            console.log("http",res.data.content.includes("http"));
            if(!res.data.content.includes("http")){
                this.string = res.data.content.replace(/src="/g, 'src="'+this.baseUrl);
            }else{
                this.string = res.data.content;
            }
            this.string = res.data.content.replace('class="ql-video"','class="ql-video" sandbox="allow-scripts"');
            if(res.data.categoryTitle !== '社区网点') {
                this.time = res.data.updateTime;
                this.writer = res.data.author;
                this.num = res.data.click;
            }
        });
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
    }
}
</script>
  
<style lang="scss">
.composition-show {
    padding: 60px 0;
    width: 1400px;
    margin: 0 auto;
    .mentality-top2 {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }

        .titlebox {
            @include sys-font(42px, #000, bold);
            margin-left: 30px;
            padding-left: 30px;
            position: relative;

            &::before {
                content: '';
                width: 2px;
                height: 46px;
                background: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .contentbox {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
        border-radius: 40px;
        padding: 80px 100px;
        box-sizing: border-box;
        position: relative;
        .titlebox {
            width: 100%;
            padding-bottom: 30px;
            margin-bottom: 30px;
            position: relative;
            text-align: center;
            &::after {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #EEEEEE;
            }
            .atitle {
                @include sys-text-hide(2);
                @include sys-font(32px, #000, bold);
                line-height: 42px;
                margin-top: -5px;
            }
            .btitle {
                @include sys-text-hide(1);
                @include sys-font(16px, #666);
                margin-top: 35px;
                span {
                    padding: 0 15px;
                }
            }
        }
        .conbox {
            @include sys-font(16px, #000);
            p {
                text-indent: 32px;
                margin-bottom: 20px;
            }
        }
    }
    .qr-c{
        text-align:center;

        div{
            text-align: center;
        }

    }
    .qrcode {
        position: fixed;
        width: 120px;
        height: 150px;
        bottom: 60px;
        right: 90px;
        background: #3F65E0;
        @include sys-picbox(130px, 191px);
    }
}
</style>